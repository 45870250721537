import { DATE_RANGE } from '@utils/constants';

export default () => {
  if (typeof localStorage !== 'undefined') {
    const values = localStorage.getItem(DATE_RANGE);

    try {
      if (values) {
        const data = JSON.parse(values);
        return data;
      }
    } catch (err) {
      return null;
    }
  }
  return undefined;
};
