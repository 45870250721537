import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';
import { showSnackbar } from 'src/redux/snackbar/actions';
import {
  AUTHORIZATION_HEADER,
  DEFAULT_ERROR_MESSAGE,
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
  RTK_API_BASE_URL,
} from 'utils/constants';

export const productStagingApi = createApi({
  reducerPath: 'productStagingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: RTK_API_BASE_URL,
  }),
  extractRehydrationInfo(action) {
    if (action.type === HYDRATE) {
      return action.payload.api;
    }
  },
  tagTypes: ['Get-Product-Staging', 'Update-Product-Staging'],
  endpoints: (builder) => ({
    productStagingData: builder.mutation({
      query: (payload) => ({
        url: 'product-staging',
        method: METHOD_POST,
        body: payload,
      }),
      providesTags: [{ type: 'Get-Product-Staging' }],
    }),
    updateDataStatus: builder.mutation({
      query: ({ payload }) => ({
        url: 'product-staging',
        method: METHOD_PUT,
        body: payload,
      }),
      async onQueryStarted(
          { onSuccessFn = () => { }, onErrorFn = (error) => { } },
          { dispatch, queryFulfilled }
      ) {
          try {
              const { data } = await queryFulfilled;
              onSuccessFn(data);
          } catch ({ error }) {
              onErrorFn(error);
          }
      },
      providesTags: [{ type: 'Update-Product-Staging' }],
    }),
  }),
});

export const { useProductStagingDataMutation, useUpdateDataStatusMutation } = productStagingApi;
