// @utils
import { SHOW_ONLY_MASTER_PRODUCTS } from "@utils/constants";

export default () => {
  if (typeof localStorage !== "undefined") {
    const value = localStorage.getItem(SHOW_ONLY_MASTER_PRODUCTS);

    try {
      if (value) {
        const info = JSON.parse(value);
        return info;
      }
    } catch (err) {
      return null;
    }
  }
  return undefined;
};
