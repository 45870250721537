// Package Imports
import crypto from 'crypto';

// Other Imports
import { CIPHER_ENCRYPTION_KEY } from './constants';

const ALGORITHM = 'aes-256-cbc';
const ENCODING = 'hex';
const IV_LENGTH = 16;
const KEY = CIPHER_ENCRYPTION_KEY;

export const encrypt = (data) => {
    const iv = crypto.randomBytes(IV_LENGTH);
    const cipher = crypto.createCipheriv(ALGORITHM, new Buffer(KEY), iv);
    return Buffer.concat([cipher.update(data,), cipher.final(), iv]).toString(ENCODING);
};

export const decrypt = (data) => {
    const binaryData = new Buffer(data, ENCODING);
    const iv = binaryData.slice(-IV_LENGTH);
    const encryptedData = binaryData.slice(0, binaryData.length - IV_LENGTH);
    const decipher = crypto.createDecipheriv(ALGORITHM, new Buffer(KEY), iv);

    return Buffer.concat([decipher.update(encryptedData), decipher.final()]).toString();
};