import { Fragment, useCallback } from 'react';
import { BellIcon } from "@heroicons/react/outline";
import { Popover, Transition } from '@headlessui/react';
import { formatDistance } from 'date-fns';

//
import { lifecycleApi, useGetLifecycleNotificationsQuery } from '@services/lifecycle';

function NotificationDropdown({ userId }) {
  const { data } = useGetLifecycleNotificationsQuery({
    userId,
  });
  const [updateLifecycleNotification] = lifecycleApi.endpoints.updateLifecycleNotification.useMutation();

  const notifList = data?.result || [];

  const handleReadNotification = useCallback(async (id) => {
    try {
      await updateLifecycleNotification({ notificationId: id });
    } catch (error) {
      // console.log(error);
    }
  }, [])

  return (
    <div className="w-full">
      <Popover className="relative top-1">
        {({ open }) => (
          <>
            <Popover.Button
              className="group rounded text-base font-medium text-white hover:text-opacity-100 relative notification-wrapper"
            >
              {notifList?.length > 0 && (
                <span className="notification-badge">{notifList?.length || 0}</span>
              )}
              <BellIcon className="w-7 h-7 stroke-1 text-gray-600" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 px-2">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-white w-screen max-w-xs flex flex-col divide-y h-full max-h-96 overflow-y-auto">
                    {notifList?.map((item) => (
                      <Popover.Button key={item.id} className="relative py-2 px-3 text-left hover:bg-gray-100" onClick={() => handleReadNotification(item.id)}>
                        <h4 className="text-[0.85rem] font-bold truncate" title={item.title}>{item.title}</h4>
                        <p className="text-[0.775rem] mt-1 whitespace-normal">{item.body}</p>
                        <p className="text-right text-xs text-gray-500">
                          {formatDistance(new Date(item?.created || new Date()), new Date(), { addSuffix: true })}
                        </p>
                      </Popover.Button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default NotificationDropdown;
