import { createSlice } from '@reduxjs/toolkit';
import getAuthToken from '@helpers/get-auth-token';
import getNavTree from '@helpers/get-nav-tree';
import getToken from '@helpers/get-token';
import getUser from '@helpers/get-user';
import getOrgSettings from '@helpers/get-org-settings';
import { encrypt } from '@utils/cipher';
import {
  API_TOKEN,
  AUTH_TOKEN,
  DATE_RANGE,
  NAV_TREE,
  ORG_SETTINGS,
  TOKEN_INFO,
} from '@utils/constants';
import Cookies from 'js-cookie';
import getDateRange from '@helpers/get-date-range';

const INITIAL_STATE = {
  user: getUser(),
  token: getToken(),
  authToken: getAuthToken(),
  navTree: getNavTree(),
  domainId: null,
  columnDefinitions: [],
  orgSettings: getOrgSettings(),
  dateRange: getDateRange(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      if (typeof Cookies !== 'undefined') {
        Cookies.set(API_TOKEN, encrypt(JSON.stringify(action.payload)));
      }
    },
    setTokens(state, action) {
      state.token = action.payload.apiToken;
      state.authToken = action.payload.authToken;
      if (typeof Cookies !== 'undefined') {
        Cookies.set(
          API_TOKEN,
          encrypt(JSON.stringify(action.payload.apiToken))
        );
      }
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(
          AUTH_TOKEN,
          encrypt(JSON.stringify(action.payload.authToken))
        );
      }
    },
    setUser(state, action) {
      state.user = action.payload;
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(
          TOKEN_INFO,
          encrypt(JSON.stringify(action.payload))
        );
      }
    },
    setNavTree(state, action) {
      state.navTree = action.payload;
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(NAV_TREE, encrypt(JSON.stringify(action.payload)));
      }
    },
    setDomainId(state, action) {
      state.domainId = action.payload;
    },
    resetUser(state) {
      state.token = null;
      state.authToken = null;
      state.user = null;
      state.domainId = null;
      Cookies.remove(API_TOKEN);
      localStorage.removeItem(AUTH_TOKEN);
    },
    setOrgSettings(state, action) {
      state.orgSettings = action.payload;
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(ORG_SETTINGS, encrypt(JSON.stringify(action.payload)));
      }
    },
    setDateRange(state, action) {
      state.dateRange = action.payload;
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(
          DATE_RANGE,
          encrypt(JSON.stringify(action.payload))
        );
      }
    },
  },
});

const { actions, reducer } = authSlice;
export const {
  setToken,
  setTokens,
  setUser,
  setNavTree,
  setDomainId,
  resetUser,
  setOrgSettings,
  setDateRange,
} = actions;
export default reducer;
