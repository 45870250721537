export const SHOW_SNACKBAR = "snackbar/show";
export const HIDE_SNACKBAR = "snackbar/hide";

export const showSnackbar = (payload) => {
  return {
    type: SHOW_SNACKBAR,
    payload,
  };
};

export const hideSnackbar = (payload) => {
  return {
    type: HIDE_SNACKBAR,
    payload,
  };
};
