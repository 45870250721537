import { stringToBoolean } from "./parse-util";

export const APP_ROUTES = {
  SSO_MIDDLEWARE: "/account/sso",
  LOGIN: "/login",
  LOGIN_ERROR: "/account/login/error",
  HOME: "/bulk-editor",
};
export const SORT_ORDER = {
  ASCENDING: 0,
  DESCENDING: 1,
}

export const UNPACKING_PAYLOAD = "Unpacking payload...";
export const VALIDATING_PAYLOAD = "Validating payload...";
export const FETCHING_DATA = "Fetching data...";

export const METHOD_GET = "GET";
export const METHOD_POST = "POST";
export const METHOD_PUT = "PUT";
export const METHOD_PATCH = "PATCH";
export const METHOD_DELETE = "DELETE";

// API Endpoints
export const RTK_API_BASE_URL = "/api";
export const LOGIN_URL = "auth/login";
export const VALIDATE_TOKEN_URL = "auth/validate-token";
export const NAV_TREE_URL = "get-nav-tree";
export const GET_ORG_URL = "get-org-settings";

//API Messages
export const DEFAULT_ERROR_MESSAGE = "Something went wrong!";

// Keys
export const API_TOKEN = "st/a1";
export const AUTH_TOKEN = "st/a2";
export const NAV_TREE = "st/nt";
export const TOKEN_INFO = "st/i";
export const GRID_COL_FIELDS = "st/gcf";
export const GRID_AUTOSAVE = "st/as";
export const AUTHORIZATION_HEADER = "Authorization";
export const ORG_SETTINGS = "st/os";
export const COUNTRY_CODE = "st/cc";
export const GENDER = "st/gd";
export const VISIBLE_COLS_STATE = "st/vcs";
export const DATE_RANGE = "st/dr";
export const SHOW_ONLY_MASTER_PRODUCTS = "st/mp";

export const HREF_PAGES = "/pages";
export const COOKIE_CHECK_AUTH_COOKIE = "checkLogout";
export const COOKIE_SUID = "SUID";
export const COOKIE_SOID = "SOID";

export const AUTH_BASE_URL = process.env.AUTH_BASE_URL;
export const BASE_URL = process.env.BASE_URL;
export const API_URL = process.env.API_URL;
export const AUTH_CURRENT_MODULE = process.env.AUTH_CURRENT_MODULE;
export const AUTH_COOKIE_VALIDATION_ENABLED = process.env.AUTH_COOKIE_VALIDATION_ENABLED;
export const BETTERSITE_PREVIEW_URL = process.env.BETTERSITE_PREVIEW_URL;
export const DAMENSCH_PREVIEW_URL = process.env.DAMENSCH_PREVIEW_URL;
export const GRID_PAGE_SIZE = process.env.GRID_PAGE_SIZE;
export const GRID_PAGING_ENABLED = process.env.GRID_PAGING_ENABLED;
export const EDIT_PAGE_AUTO_SAVE_ENABLED = process.env.EDIT_PAGE_AUTO_SAVE_ENABLED ? stringToBoolean(process.env.EDIT_PAGE_AUTO_SAVE_ENABLED) : false;
export const EDIT_PAGE_AUTO_SAVE_INTERVAL_IN_SECS = process.env.EDIT_PAGE_AUTO_SAVE_INTERVAL_IN_SECS;
export const CIPHER_ENCRYPTION_KEY = process.env.CIPHER_ENCRYPTION_KEY;
export const ENABLE_ALERT_LOG = stringToBoolean(process.env.ENABLE_ALERT_LOG);
export const DEFAULT_PARENT_SLUG = "/";
export const PLM_BASE_URL = process.env.PLM_BASE_URL;
export const PLM_API_URL = process.env.PLM_API_URL;
export const DATE_FORMAT = process.env.DATE_FORMAT;
export const TIME_FORMAT = process.env.TIME_FORMAT;
export const TIME_SECONDS_FORMAT = process.env.TIME_SECONDS_FORMAT;
export const PRODUCT_EDITOR_BASE_URL = process.env.PRODUCT_EDITOR_BASE_URL;
export const BACKEND_ELASTIC_SEARCH_API = process.env.BACKEND_ELASTIC_SEARCH_API;
export const BACKEND_ELASTIC_SEARCH_KEY = process.env.BACKEND_ELASTIC_SEARCH_KEY;
export const BACKEND_ELASTIC_SEARCH_PRIVATE_KEY = process.env.BACKEND_ELASTIC_SEARCH_PRIVATE_KEY;

// grid constants
export const PAGINATION_PAGE_SIZE = 40
export const SUPPRESS_GRID_COLS_FROM_TOOL_PANEL = [ "stockcode" ]
export const DEFAULT_GRID_COLS_IN_LIST_VIEW = [ "stockcode", "name", "category", "sellprice", "listprice", "image" ]
export const CHECKBOX_ENABLED_GRID_COLS = [ "stockcode" ]
export const DYNAMIC_CELL_RENDERER_FIELDS = {
  "LISTPRICE": "listprice",
  "SELLPRICE": "sellprice",
  "IMAGE": "image",
  "STATUS": "status",
  "COUNTRYOFORIGIN": "countryoforigin",
  "GENDER": "gender",
}

export const GRID_COL_WIDTH = 250
export const EmptyGuid = "00000000-0000-0000-0000-000000000000";
export const EmptyString = "";

export const TREE_SELECT = {
  valueName: 'value',
  labelName: 'label',
  nestedOptsName: 'children',
}

export const TAXONOMY_NAMES = {
  status: 'Status',
  tag: 'Tag',
  collection: 'collection',
  vendor: 'vendor',
  brand: 'brand',
  'hsn-code': 'HSN Code',
  coo: 'Country Of Origin',
}

export const TAXONOMY_INPUT_NAMES = {
  status: 'status',
  tag: 'tags',
  collection: 'collectionId',
  vendor: 'vendorId',
  brand: 'brandId',
  'hsn-code': 'hsnCode',
  coo: 'countryOfOrigin',
}

export const MASTERDATA_VALUES = {
  'hsn-code': '1800', // same as HSN Code
  hsncode: '1800', // same as HSN Code
  coo: '25', // same as Country Of Origin
  countryoforigin: '25', // same as Country Of Origin
  gender: '22',
}

export const SPECIFIC_FIELDS = {
  status: 'status',
  tag: 'tag',
  collection: 'collection',
  supplierid: 'supplier',
  brand: 'brand',
  category: 'category',
}

export const PRODUCT_STATUS = {
  0: 'None',
  1: 'Draft',
  2: 'Active',
  3: 'Archived',
  4: 'Discontinued',
}

export const TASK_STATUS = {
  1: 'Open',
  2: 'In progress',
  3: 'Completed',
}

export const TASK_STATUS_VALS = {
  OPEN: 1,
  INPROGRESS: 2,
  COMPLETED: 3,
}

// alert box
export const ALERT_BOX_HIDE_DELAY = 5000;
export const ALERT_BOX_ERROR_VARIANT = "danger";
export const ALERT_BOX_INFO_VARIANT = "success";

// lifecycle step colors
export const LIFECYCLE_STEPS_COLORS = [
  "gray-600",
  "gray-200",
  "red-600",
  "red-900",
  "orange-500",
  "orange-700",
  "pink-400",
  "pink-900",
  "blue-600",
  "blue-900",
  "emerald-200",
  "emerald-400",
  "emerald-600",
  "amber-200",
  "amber-800",
  "amber-400",
  "indigo-500",
  "indigo-800",
  "purple-500",
  "purple-800",
];

export const BOOLEAN_LABELS = {
  'true': 'Yes',
  true: 'Yes',
  '1': 'Yes',
  'True': 'Yes',
  'false': 'No',
  false: 'No',
  '0': 'No',
  'False': 'No',
}

export const INPUT_PLACEHOLDER = {
  DATE: 'Date',
  DATETIME: 'Date & Time',
  ANY_FIELD: 'Select any field',
  SELECT: 'Select ',
}

export const INPUT_TYPES = {
  '1': 'text',
  '2': 'select',
  '3': 'longText',
  '4': 'date',
  '5': 'radio',
  '6': 'multi-select',
  '8': 'color',
  '9': 'rich-text',
  '12': 'date-time',
  '13': 'multi-level-select',
  '14': 'number',
}

export const LIFECYCLE_STAGE_COLORS = {
  'Draft': 'gray-500',
  'Active': 'blue-400',
  'Archived': 'emerald-200',
  'Discontinued': 'red-800',
  'Enrich Attributes': 'gray-200',
  'Upload Images': 'gray-200',
  'Update Description': 'gray-200',
  'Setup Pricing': 'gray-200',
  'Update Costing': 'gray-200',
  'Update Basic info': 'gray-200',
  'Created': 'gray-200',
  'Publish to Channels': 'blue-600',
  'Review Pricing - L2': 'blue-600',
  'Review Pricing - L1':'blue-600',
  'Update SEO Info': 'blue-600',
  'Mark Active': 'blue-600',
}
export const DEFAULT_HSN_CODE_SEARCH_TXT = '100';

export const BOOLEAN_VALS = {
  true: '1',
  false: '0',
  'true': '1',
  'false': '0',
  '0': '0',
  '1': '1',
}

export const PRODUCT_LIST_DATE_FORMAT = 'YYYY-MM-DD'

export const ProductStagingStatus = {
  ALL: 0,
  SUBMITTED: 1,
  APPROVED: 2,
  REJECTED: 3,
}

export const MyTaskStatus = {
  'open': 1,
  'in progress': 2,
  'completed': 3,
}

export const MyTaskActions = {
  'data updated': 1,
  'approved for next state': 2,
  'rejected for next state': 3,
}

export const COL_BASIC_INFO_HEADER_NAME = "Basic Information";

export const FIELD_VALUE_SEPERATOR = ',';