import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';
import { RTK_API_BASE_URL } from 'utils/constants';

export const ruleEditorApi = createApi({
  reducerPath: 'ruleEditorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: RTK_API_BASE_URL,
    /*prepareHeaders: (headers, { getState }) => {
            const { token } = getState().auth;

            if (token) {
                headers.set(AUTHORIZATION_HEADER, token);
            }
            return headers;
        },*/
  }),
  extractRehydrationInfo(action) {
    if (action.type === HYDRATE) {
      return action.payload.api;
    }
  },
  tagTypes: ['Get Form'],
  endpoints: (builder) => ({
    getFormByCode: builder.query({
      query: ({ code }) => ({
        url: `form/${code}`,
      }),
      providesTags: [{ type: 'Get Form' }],
    }),
  }),
});

export const { useGetFormByCodeQuery } = ruleEditorApi;
