import { combineReducers } from "redux";
import authReducer from "./auth";
import gridReducer from "./grid";

import {
  authApi,
  rolesApi,
  bulkEditorApi,
  lifecycleApi,
  productFamilyApi,
  ruleEngineApi,
  ruleEditorApi,
  productStagingApi,
} from '@services/index';

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [bulkEditorApi.reducerPath]: bulkEditorApi.reducer,  
  [lifecycleApi.reducerPath]: lifecycleApi.reducer,  
  [productFamilyApi.reducerPath]: productFamilyApi.reducer,  
  [ruleEngineApi.reducerPath]: ruleEngineApi.reducer,
  [ruleEditorApi.reducerPath]: ruleEditorApi.reducer,
  [productStagingApi.reducerPath]: productStagingApi.reducer,
  auth: authReducer,
  grid: gridReducer,
});

export default rootReducer;
