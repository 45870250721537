// @utils
import { GRID_AUTOSAVE } from "@utils/constants";

export default () => {
  if (typeof localStorage !== "undefined") {
    const gridAutoSave = localStorage.getItem(GRID_AUTOSAVE);

    try {
      if (gridAutoSave) {
        const info = JSON.parse(gridAutoSave);
        return info;
      }
    } catch (err) {
      return null;
    }
  }
  return undefined;
};
