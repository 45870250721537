import { ProductStagingStatus } from '@utils/constants';

export const SOMETHING_WENT_WRONG = 'Something went wrong!';

export const PRODUCT_STAGING_DATA_UPDATE = {
  [ProductStagingStatus.APPROVED]: 'Data approved successfully.',
  [ProductStagingStatus.REJECTED]: 'Data rejected successfully.',
};

export const PRODUCT_STAGING = {
  REQUEST_FOR_UPDATE: 'Your request to update the data has been submitted for approval.',
  ALL: 'All',
  SUBMITTED: 'Submitted',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
}
